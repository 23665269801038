@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
  margin: 0;
  padding: 0;
}

.logo-container {
  width: 100%;
  overflow: hidden;
  /* Ensure the content doesn't overflow outside the card */
}

.scroll-container {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  position: relative;
  height: 50px;
  /* Adjust the height based on your text size */
  background-color: #fff;
  border: 1px solid #ddd;
  background-color: red;
}

.scroll-text {
  display: inline-block;
  position: absolute;
  /* Start off the screen to the right */
  animation: scroll-left 15s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(135%);
    /* Start off the right side */
  }

  100% {
    transform: translateX(-135%);
    /* Move to the left side */
  }
}

@media (max-width: 768px) {

  /* Small screens */
  .small-screen-image {
    display: block;
  }

  .large-screen-image {
    display: none;
  }
}

@media (min-width: 768px) {

  /* Large screens */
  .small-screen-image {
    display: none;
  }

  .large-screen-image {
    display: block;
  }
}

@media (max-width: 1024px) {

  /* Small screens */
  .small-screen-images {
    display: block;
  }

  .large-screen-images {
    display: none;
  }
}

@media (min-width: 1024px) {

  /* Large screens */
  .small-screen-images {
    display: none;
  }

  .large-screen-images {
    display: block;
  }
}

.header-container {
  position: sticky;
  top: 0;
  /* Sticks to the top */
  z-index: 1000;
  /* Ensure it stays above other content */
  background-color: white;
  /* Optional: To give it a background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional: Add shadow for better visibility */
}

.men1.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #262626;
  /* Match the background color */
  z-index: 50;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Optional: shadow for better visibility */
}


.header-content {
  background-color: white;
  /* Ensure the header has a background */
}

.large-screen-nav {
  position: relative;
  /* Ensure the navigation remains within header context */
  z-index: 900;
  /* Lower than the header but still above main content */
}

.small-screen-search {
  position: relative;
  /* Similar to above */
  z-index: 900;
  /* Lower than the header */
}

/* Banner.css */
.banner-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);
  /* Hide by default */
}

.banner-container.visible {
  transform: translateY(0);
  /* Show when scrolling down */
}

.image-container {
  display: flex;
  overflow: hidden;
}

.banner-image {
  width: calc(100vw / 4);
  /* Show 3 images at a time */
  /* max-width: 33.33vw; Ensure they don't overflow */
  padding: 0 5px;
}

.arrow {
  background: none;
  border: none;
  color: black;
  font-size: 2em;
  cursor: pointer;
  /* padding: 10px; */
}

.arrow.left {
  margin-right: 3px;
}

.arrow.right {
  margin-left: 3px;
}




.color-text {
  color: #03053d;
}

.homepage_highcourt_dropdown .modal-body {
  padding: 0;
}

.mob_display_only {
  display: none;
}

.header_suscription {
  background-color: #f3f3f3;
}

.subscription_heading {
  font-size: 36px;
  text-align: center;
  background: linear-gradient(90.11deg, #e22033 3.72%, #090a0b 97.63%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subscription_button {
  background: linear-gradient(115.1deg, #e22033 -31.96%, #090a0b 92.9%);
  font-size: 16px;
  border-radius: 8px;
  color: #fefefe;
  padding: 13px 60px;
  /* font-family: 'Roboto Bold', sans-serif; */
  width: 200px;
  margin: auto;
}

.subscription_buttonlogin {
  background: linear-gradient(115.1deg, #e22033 -31.96%, #090a0b 92.9%);
  font-size: 16px;
  border-radius: 8px;
  color: #fefefe;
  padding: 13px 60px;
  /* font-family: 'Roboto Bold', sans-serif; */
  /* width: 200px; */
  margin: auto;
}

.subscription_subheading {
  font-size: 24px;
  text-align: center;
  color: #0a0a0a;
}

.subscription_price {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 60px;
}

.subscription_card {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
}




/* Keyframes for big-small animation */
@keyframes bigSmall {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
    /* Adjust this scale value for the desired "big" effect */
  }
}

/* Animation class */
.big-small-animation {
  animation: bigSmall 2s infinite;
  /* Adjust duration and iteration as needed */
}




/* Login Page css */
/* Global Styles */
.loginpage_cntr1 {
  width: 100% !important;
}

.bellicon_img {
  padding-top: 9px;
}

/* Subcontainer Styles */
.loginpage_subcntr2_subcol2_details {
  font-size: 14px;
  line-height: 1.5;
  padding: 2px;
  color: #363636;
}

.loginpage_subcntr2_subcol_row {
  margin-top: 30px;
}

/* Progress Bar Styles */
.loginpage_subcol_progressbar {
  padding-left: 0;
}

.loginpage_subcol_progressbar ul {
  margin-left: -15px;
}

.loginpage_subcol_progressbar ul li {
  display: inline-block;
  width: 31%;
  position: relative;
}

.loginpage_subcol_progressbar p {
  color: #a8afb6;
}

.loginpage_subcol_progressbar .fa,
.loginpage_subcol_progressbar .fas {
  color: #a8afb6;
}

.loginpage_subcol_progressbar ul li .fa::after,
.loginpage_subcol_progressbar ul li .fas::after {
  content: '';
  background: #a8afb6;
  height: 5px;
  width: 83%;
  display: block;
  position: absolute;
  left: 0;
  margin-top: -11px;
  margin-left: 17px;
  border-radius: 20px;
}

.loginpage_subcol_progressbar ul li:nth-child(1) .fa,
.loginpage_subcol_progressbar ul li:nth-child(1) .fas {
  color: #33a7ff;
  background: #33a7ff;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  font-size: 8px;
  padding: 3px 4px;
}

.loginpage_subcol_progressbar ul li:nth-child(1) .fa::after {
  background: #33a7ff;
}

.loginpage_subcol_progressbar ul li:nth-child(2) .fas,
.loginpage_subcol_progressbar ul li:nth-child(2) .fas::after {
  background: #e22033;
}

.loginpage_subcol_progressbar ul li:nth-child(3) .payment_fail_summary,
.loginpage_subcol_progressbar ul li:nth-child(3) .payment_fail_summary::after {
  background: #e22033;
  margin-top: -6px;
}

.loginpage_progressbar_fas {
  color: #fff !important;
  background: #33a7ff;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  font-size: 8px;
  padding: 3px 4px;
}

/* Logo and Subscription Styles */
.loginpage_subcol_logo {
  margin-left: -15px;
}

.loginpage_subcol_subscription {
  background: #363636;
  border-radius: 4px;
  margin: 24px 30px 30px 0;
}

.loginpage_subcol_subscription_year,
.loginpage_subcol_subscription_title,
.loginpage_subcol_subscription_price {
  color: #fefefe;
}

.loginpage_subcol_subscription_year {
  font-size: 17px;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

.loginpage_subcol_subscription_title {
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  text-transform: lowercase;
  font-family: robotoregular;
  margin-top: -8px;
}

.loginpage_subcol_subscription_price {
  font-size: 24px;
  line-height: 1.5;
  margin: 13px;
  text-align: center;
  letter-spacing: 0.02em;
}

.loginpage_subcol_subscription_change_cols {
  border-left: 1px solid #626262;
  height: 41px;
  margin-top: 12px;
}

.loginpage_subcol_subscription_change,
.loginpage_subcol_subscription_change a {
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  font-family: robotobold;
  margin-top: 8px;
}

.loginpage_subcol_subscription_change a:hover {
  color: #fff;
  text-decoration: none;
}

/* Login Page Styles */
.loginpage_subcol_login {
  font-size: 24px;
  line-height: 1;
  color: #363636;
  margin: 8px 0 5px;
}

.loginpage_subcol_login_content,
.loginpage_subcol_login_content_second {
  font-size: 14px;
  line-height: 1.5;
  color: #373c41;
  margin-bottom: 20px;
}

.loginpage_btn {
  padding: 12px;
  font-size: 16px;
  height: 48px;
  line-height: 1.5;
  color: #363636;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  margin-bottom: 16px;
  width: 100%;
  text-align: left;
  padding-left: 28%;
}

.loginpage_btn_img {
  margin-right: 7px;
}

.loginpage_subscription_login {
  font-size: 14px;
  line-height: 1.5;
  color: #363636;
}

.loginpage_text_login {
  font-size: 16px;
  line-height: 1.5;
  color: #e8192d;
  margin-left: 12px;
  font-family: robotobold;
}

.loginpage_col2 {
  height: 90vh;
}

.loginpage_form .form-control:hover,
.loginpage_form .form-control:active,
.loginpage_form .form-control:focus {
  outline: 0 !important;
  border: 1px solid #2eb69b;
  border-radius: 4px;
  box-shadow: none !important;
}

.loginpage_form_control {
  border-left: none !important;
  border-top-right-radius: none !important;
}

.loginpage_eye_icon {
  position: absolute;
  z-index: 0;
  margin-left: 84%;
  padding-top: 15px;
}

.loginpage_eye_icon_img {
  width: 20px;
  height: 20px;
}

.loginpage_form .btn-primary {
  background: linear-gradient(115.1deg, #e22033 -31.96%, #090a0b 92.9%) !important;
  border-radius: 4px;
  width: 100%;
  border: none !important;
  margin-top: 32px;
  font-size: 18px;
  height: 48px;
  font-family: robotobold;
}

.loginpage_forgot_password {
  float: right;
}

.loginpage_forgot_password_link {
  font-size: 14px;
  line-height: 1.5;
  color: #e8192d;
}

.loginpage_form_select {
  word-wrap: normal;
  height: 48px;
  border: 1px solid #d5d8dc;
  color: #a8afb6;
  font-size: 14px;
  padding: 0 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
}

.loginpage_form_group:active {
  border: 1px solid #2eb69b;
}

.input-group-text {
  background-color: #fff;
  color: #a8afb6;
  font-size: 14px;
  border: none;
}

.input-group,
.input-group:active,
.input-group:hover {
  position: relative;
  display: flex;
  border-collapse: separate;
  outline: 0 !important;
  border: 1px solid #2eb69b !important;
  border-radius: 4px;
}

.input-group-text:focus-visible,
.input-group-text:active,
.input-group-text:focus {
  border: none !important;
}

.loginpage_form_control_mobile:active,
.loginpage_form_control_mobile:hover {
  border: none !important;
}

.loginpage_seperation {
  display: flex;
  margin-bottom: 24px;
}

.loginpage_form .form-control {
  height: 48px;
}

.line {
  height: 1px;
  width: 50%;
  background-color: #d5d8dc;
  margin-top: 10px;
}

.or {
  width: 10%;
  font-size: 14px;
  color: #6f767d;
  line-height: 15.4px;
}

.loginpage_forgot_password_title {
  padding-top: 107px;
}

.loginpage_reset_password_title {
  color: #33a7ff;
}

.loginpage_subcol_check_circle_img {
  padding-top: 132px;
  padding-bottom: 27px;
}

.message-valid {
  margin-left: 13px;
}

#message h5 {
  line-height: 1.5;
}

.loginpage_subcol_back_icon_new_password {
  display: none;
}

.loginpage_password_changed {
  padding: 12px;
}

/* Payment Styles */
.loginpage_col2_payment {
  background: #f5f7f8;
  padding: 151px 90px 0 90px;
}

.payment_fail_summary {
  font-size: 24px;
  color: #e22033;
  margin-top: -3px;
  text-align: left;
  font-family: robotobold;
}

.payment_fail_summary_content,
.payment_fail_summary_content_second {
  font-size: 16px;
  color: #363636;
  margin-bottom: 22px;
  margin-top: 12px;
  text-align: left;
  line-height: 1.5;
}

.payment_fail_button .btn-primary {
  font-size: 18px;
  height: 48px;
  font-family: robotobold;
  background: linear-gradient(115.1deg, #e22033 -31.96%, #090a0b 92.9%);
  border-radius: 4px;
  width: 100%;
  border: none;
  margin-top: 23px;
}

.payment_fail_message {
  color: #e8192d;
}

.payment_fail_button {
  margin-bottom: 50px;
}

.payment_fail_homepage_link {
  font-size: 16px;
  color: #363636;
  font-family: robotobold;
  text-align: center;
  margin-left: 23%;
}

.loginpage_subcntr2_subcol_img {
  max-width: revert;
}

span.subscribe-now-btn {
  cursor: pointer;
}

.testimonial-item .testimonial_row2 {
  min-height: 254px;
}

.testimonial-item .testimonial_row2 img {
  border-radius: 50%;
}

.subscription-page .profile-pic {
  max-height: 60px;
}

.account_subscriber_img img {
  width: auto;
  height: auto;
  max-height: 183px;
  max-width: 183px;
}

.account_subscriber_img {
  display: flex;
  justify-content: center;
}

.account_billing_history .active-plan {
  color: green;
}

.account_billing_history .expired-plan {
  color: red;
}

.profile-page .account_billing p {
  margin-top: 5px;
  margin-bottom: 0;
}

.laptop-big-story {
  display: block;
}

.mobile-big-story {
  display: none;
}

@media screen and (max-width: 767px) {
  .laptop-big-story {
    display: none;
  }

  .mobile-big-story {
    display: block;
  }
}

.ad-block.bigstory_block {
  width: -webkit-fill-available;
}

.ad-block.bigstory_block h3 {
  padding-right: 20px;
}

.ad-block.subscribe_ad_block a::before {
  content: none;
}

.ad_block_cntr a::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: -webkit-fill-available;
  height: 100%;
  display: block;
  background: linear-gradient(to bottom, transparent 0%, #000 100%);
  margin: 0 15px;
  border-radius: 8px;
}

/* .loginpage_subcntr2_subcol_img{max-width:revert}span.subscribe-now-btn{cursor:pointer}.testimonial-item .testimonial_row2{min-height:254px}.testimonial-item .testimonial_row2 img{border-radius:50%}.subscription-page .profile-pic{max-height:60px}.account_subscriber_img img{width:auto;height:auto;max-height:183px;max-width:183px}.account_subscriber_img{display:flex;justify-content:center} */

/* .loginpage_subcol_progressbar p{font-size:12px;color:#a8afb6}.loginpage_payment_buttons{margin:10px!important}.loginpage_payment_buttons_cols a{height:36px}.loginpage_header_extng_user_col a{color:#e22033!important;font-size:12px!important;border-radius:8px;border:1px solid #e22033;border-radius:8px}.loginpage_col2_payment{padding:20px 20px 20px 20px;height:auto}.loginpage_eye_icon{margin-left:91%}.loginpage_subcol_login{font-size:18px}.login_continue_with{margin-right:-15px;margin-left:-15px}.loginpage_subcol_zip_img{padding-top:10px}.loginpage_subcol_progressbar ul li{width:32.4%}.loginpage_subcol_progressbar ul li .fa::after{width:82.3%}.loginpage_subcol_progressbar ul{margin-left:0;margin-top:20px;padding:initial}.loginpage_subcol_progressbar{padding-left:16px}.loginpage_subcol_subscription{margin-top:10px;margin-bottom:10px;margin-right:-15px;margin-left:-15px}.loginpage_subcol_subscription_year{font-size:16px;letter-spacing:0}.loginpage_subcol_subscription_price{font-size:20px}.loginpage_btn{padding:12px 0;font-size:14px;text-align:center}.loginpage_forgot_password_button{margin-top:20px!important}.loginpage_forgot_password_login{text-align:center}.loginpage_subcol_check_circle_img{padding-top:82px}.loginpage_forgot_password_title{padding-top:32px}}@media only screen and (min-device-width :768px) and (max-device-width :1024px){.loginpage_btn{padding:12px}.loginpage_eye_icon{margin-left:86%}.loginpage_subcol_check_circle_img{padding-top:65px}.loginpage_col2_payment{padding:55px 55px 0 55px}.loginpage_payment_buttons{margin:45px!important}}@media only screen and (min-device-width :1400px) and (max-device-width :1473px){.loginpage_subcol_progressbar ul li{width:109px}.loginpage_subcol_progressbar ul li .fa::after{width:82px}}@media only screen and (min-device-width:992px) and (max-device-width:1024px){.loginpage_eye_icon{margin-left:86%!important}} */

.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.menu-container {
  transition: all 3s ease-in-out;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.continue-p {
  font-size: 12px;
  color: #b2b2b2;
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
}





/* Admin Panel css */
#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 280px;
  height: 100%;
  /* background: var(--light); */
  z-index: 2000;
  font-family: var(--lato);
  transition: .3s ease;
  overflow-x: hidden;
  scrollbar-width: none;
}

#sidebar::--webkit-scrollbar {
  display: none;
}

#sidebar.hide {
  width: 60px;
}

#sidebar .brand {
  font-size: 24px;
  font-weight: 700;
  height: 56px;
  display: flex;
  align-items: center;
  color: var(--blue);
  position: sticky;
  top: 0;
  left: 0;
  /* background: var(--light); */
  z-index: 500;
  padding-bottom: 20px;
  box-sizing: content-box;
}

#sidebar .brand .bx {
  min-width: 60px;
  display: flex;
  justify-content: center;
}

#sidebar .side-menu {
  width: 100%;
  margin-top: 48px;
}

#sidebar .side-menu li {
  height: 48px;
  /* background: transparent; */
  margin-left: 6px;
  border-radius: 48px 0 0 48px;
  padding: 4px;
}

#sidebar .side-menu li.active {
  /* background: var(--grey); */
  position: relative;
}

#sidebar .side-menu li.active::before {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: -40px;
  right: 0;
  box-shadow: 20px 20px 0 var(--grey);
  z-index: -1;
}

#sidebar .side-menu li.active::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  bottom: -40px;
  right: 0;
  /* background-color: #03053d ; */
  box-shadow: 20px -20px 0 var(--grey);
  z-index: -1;
}

#sidebar .side-menu li a {
  width: 100%;
  height: 100%;
  /* background: var(--light); */
  display: flex;
  align-items: center;
  border-radius: 48px;
  font-size: 16px;
  /* color: var(--dark); */
  white-space: nowrap;
  overflow-x: hidden;
}

/* #sidebar .side-menu.top li.active a {
  color: var(--blue);
} */

#sidebar.hide .side-menu li a {
  width: calc(48px - (4px * 2));
  transition: width .3s ease;
}

/* #sidebar .side-menu li a.logout {
  color: var(--red);
}

#sidebar .side-menu.top li a:hover {
  color: var(--blue);
} */

#sidebar .side-menu li a .bx {
  min-width: calc(60px - ((4px + 6px) * 2));
  display: flex;
  justify-content: center;
}

#content {
  position: relative;
  width: calc(100% - 280px);
  left: 280px;
  transition: .3s ease;
}

#sidebar.hide~#content {
  width: calc(100% - 60px);
  left: 60px;
}




/* NAVBAR */
#content nav {
  height: 56px;
  background: var(--light);
  padding: 0 24px;
  display: flex;
  align-items: center;
  grid-gap: 24px;
  font-family: var(--lato);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
}

#content nav::before {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -40px;
  left: 0;
  border-radius: 50%;
  box-shadow: -20px -20px 0 var(--light);
}

#content nav a {
  color: var(--dark);
}

#content nav .bx.bx-menu {
  cursor: pointer;
  color: var(--dark);
}

#content nav .nav-link {
  font-size: 16px;
  transition: .3s ease;
}

#content nav .nav-link:hover {
  color: var(--blue);
}

#content nav form {
  max-width: 400px;
  width: 100%;
  margin-right: auto;
}

#content nav form .form-input {
  display: flex;
  align-items: center;
  height: 36px;
}

#content nav form .form-input input {
  flex-grow: 1;
  padding: 0 16px;
  height: 100%;
  border: none;
  background: var(--grey);
  border-radius: 36px 0 0 36px;
  outline: none;
  width: 100%;
  color: var(--dark);
}

#content nav form .form-input button {
  width: 36px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--blue);
  color: var(--light);
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 0 36px 36px 0;
  cursor: pointer;
}

#content nav .notification {
  font-size: 20px;
  position: relative;
}

#content nav .notification .num {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--light);
  background: var(--red);
  color: var(--light);
  font-weight: 700;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#content nav .profile img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}

#content nav .switch-mode {
  display: block;
  min-width: 50px;
  height: 25px;
  border-radius: 25px;
  background: var(--grey);
  cursor: pointer;
  position: relative;
}

#content nav .switch-mode::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: calc(25px - 4px);
  background: var(--blue);
  border-radius: 50%;
  transition: all .3s ease;
}

#content nav #switch-mode:checked+.switch-mode::before {
  left: calc(100% - (25px - 4px) - 2px);
}

/* NAVBAR */





/* MAIN */
#content main {
  width: 100%;
  padding: 36px 24px;
  font-family: var(--poppins);
  max-height: calc(100vh - 56px);
  overflow-y: auto;
}

#content main .head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}

#content main .head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--dark);
}

#content main .head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}

#content main .head-title .left .breadcrumb li {
  color: var(--dark);
}

#content main .head-title .left .breadcrumb li a {
  color: var(--dark-grey);
  pointer-events: none;
}

#content main .head-title .left .breadcrumb li a.active {
  color: var(--blue);
  pointer-events: unset;
}

#content main .head-title .btn-download {
  height: 36px;
  padding: 0 16px;
  border-radius: 36px;
  background: var(--blue);
  color: var(--light);
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  font-weight: 500;
}




#content main .box-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 24px;
  margin-top: 36px;
}

#content main .box-info li {
  padding: 24px;
  background: var(--light);
  border-radius: 20px;
  display: flex;
  align-items: center;
  grid-gap: 24px;
}

#content main .box-info li .bx {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#content main .box-info li:nth-child(1) .bx {
  background: var(--light-blue);
  color: var(--blue);
}

#content main .box-info li:nth-child(2) .bx {
  background: var(--light-yellow);
  color: var(--yellow);
}

#content main .box-info li:nth-child(3) .bx {
  background: var(--light-orange);
  color: var(--orange);
}

#content main .box-info li .text h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--dark);
}

#content main .box-info li .text p {
  color: var(--dark);
}





#content main .table-data {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-top: 24px;
  width: 100%;
  color: var(--dark);
}

#content main .table-data>div {
  border-radius: 20px;
  background: var(--light);
  padding: 24px;
  overflow-x: auto;
}

#content main .table-data .head {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 24px;
}

#content main .table-data .head h3 {
  margin-right: auto;
  font-size: 24px;
  font-weight: 600;
}

#content main .table-data .head .bx {
  cursor: pointer;
}

#content main .table-data .order {
  flex-grow: 1;
  flex-basis: 500px;
}

#content main .table-data .order table {
  width: 100%;
  border-collapse: collapse;
}

#content main .table-data .order table th {
  padding-bottom: 12px;
  font-size: 13px;
  text-align: left;
  border-bottom: 1px solid var(--grey);
}

#content main .table-data .order table td {
  padding: 16px 0;
}

#content main .table-data .order table tr td:first-child {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  padding-left: 6px;
}

#content main .table-data .order table td img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

#content main .table-data .order table tbody tr:hover {
  background: var(--grey);
}

#content main .table-data .order table tr td .status {
  font-size: 10px;
  padding: 6px 16px;
  color: var(--light);
  border-radius: 20px;
  font-weight: 700;
}

#content main .table-data .order table tr td .status.completed {
  background: var(--blue);
}

#content main .table-data .order table tr td .status.process {
  background: var(--yellow);
}

#content main .table-data .order table tr td .status.pending {
  background: var(--orange);
}


#content main .table-data .todo {
  flex-grow: 1;
  flex-basis: 300px;
}

#content main .table-data .todo .todo-list {
  width: 100%;
}

#content main .table-data .todo .todo-list li {
  width: 100%;
  margin-bottom: 16px;
  background: var(--grey);
  border-radius: 10px;
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#content main .table-data .todo .todo-list li .bx {
  cursor: pointer;
}

#content main .table-data .todo .todo-list li.completed {
  border-left: 10px solid var(--blue);
}

#content main .table-data .todo .todo-list li.not-completed {
  border-left: 10px solid var(--orange);
}

#content main .table-data .todo .todo-list li:last-child {
  margin-bottom: 0;
}

/* MAIN */
/* CONTENT */









@media screen and (max-width: 768px) {
  #sidebar {
    width: 200px;
  }

  #content {
    width: calc(100% - 60px);
    left: 200px;
  }

  #content nav .nav-link {
    display: none;
  }
}






@media screen and (max-width: 576px) {
  #content nav form .form-input input {
    display: none;
  }

  #content nav form .form-input button {
    width: auto;
    height: auto;
    background: transparent;
    border-radius: none;
    color: var(--dark);
  }

  #content nav form.show .form-input input {
    display: block;
    width: 100%;
  }

  #content nav form.show .form-input button {
    width: 36px;
    height: 100%;
    border-radius: 0 36px 36px 0;
    color: var(--light);
    background: var(--red);
  }

  #content nav form.show~.notification,
  #content nav form.show~.profile {
    display: none;
  }

  #content main .box-info {
    grid-template-columns: 1fr;
  }

  #content main .table-data .head {
    min-width: 420px;
  }

  #content main .table-data .order table {
    min-width: 420px;
  }

  #content main .table-data .todo .todo-list {
    min-width: 420px;
  }
}

/* admin.css */
.user-details-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  /* Ensures that the columns are aligned */
}

.user-details-table th,
.user-details-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  /* Align text to the left */
}

.user-details-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  width: 33%;
  /* Ensures equal distribution of column width */
}

.user-details-table td {
  overflow: hidden;
  /* Ensure content does not overflow */
  text-overflow: ellipsis;
  white-space: nowrap;
  /* Keep text in a single line */
}

.user-details-table tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Alternating row colors */
}

.user-details-table tr:hover {
  background-color: #f1f1f1;
  /* Hover effect for rows */
}

/* Add this to your existing CSS file or create a new one */

.news-container {
  padding: 20px;
  background: #f5f5f5;
  border-radius: 5px;
  margin-top: 20px;
}

.news-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.form-control {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.upload-button {
  cursor: pointer;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #3e8e41;
}

img.mt-2 {
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.app.dark {
  background-color: #111;
  color: rgb(156, 156, 156);

  .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }

  .table {
    background-color: #111;

    .tableCell {
      color: gray;
    }
  }

  .navbar {
    color: #999;
    border-color: #333;

    .search {
      border-color: gray;
    }
  }

  .sidebar {
    background-color: #111;
    border-color: #333;

    .top {
      .logo {
        color: #999;
      }
    }

    hr {
      border-color: #333;
    }

    ul {
      li {
        &:hover {
          background-color: #333;
        }

        .icon {
          color: #999;
        }
      }
    }
  }

  .datatable {
    .datagrid {
      color: gray;
      border: none;

      .viewButton,
      .deleteButton,
      .cellWithStatus {
        color: gray;
        border: none;
      }
    }
  }

  input {
    background-color: transparent;
  }
}

.table {
  .cellWrapper {
    display: flex;
    align-items: center;

    .image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }
  }

  .status {
    padding: 5px;
    border-radius: 5px;

    &.Approved {
      color: green;
      background-color: rgba(0, 128, 0, 0.151);
    }

    &.Pending {
      color: goldenrod;
      background-color: rgba(189, 189, 3, 0.103);
    }
  }
}

/* Base class for transition */
.answer-container {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;
  /* Initial state: collapsed */
}

/* Expanded state */
.answer-expanded {
  max-height: 1000px;
  /* Large enough value to fit the content */
  transition: max-height 2s ease-in-out;
}

/* Adjust duration for longer transition (3 seconds) */
.answer-container-long {
  transition: max-height 2s ease-in-out;
}

.widget {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 10px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: 100px;

  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-weight: bold;
      font-size: 14px;
      color: rgb(160, 160, 160);
    }

    .counter {
      font-size: 28px;
      font-weight: 300;
    }

    .link {
      width: max-content;
      font-size: 12px;
      border-bottom: 1px solid gray;
    }

    .percentage {
      display: flex;
      align-items: center;
      font-size: 14px;

      &.positive {
        color: green;
      }
      &.negative {
        color: red;
      }
    }

    .icon {
      font-size: 18px;
      padding: 5px;
      border-radius: 5px;
      align-self: flex-end;
    }
  }
}


.search-container {
  position: relative;
}

.menu-containe {
  z-index: 1000;
}

.search-input {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.search-result-item {
  padding: 8px;
  cursor: pointer;
}

.search-result-item:hover {
  background-color: #f0f0f0;
}
